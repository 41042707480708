<template>
  <Slide data-t="carousel-item">
    <slot />
  </Slide>
</template>

<script>
import { defineComponent } from 'vue'
import { Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  /* 
    так как это компонент обертка
    пришлость его делать на Options Api
    так как слайды прокидываются в карусель
    по имени компонента 'CarouselSlide'
  */
  name: 'CarouselSlide',
  components: {
    Slide,
  },
})
</script>
