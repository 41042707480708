<template>
  <div class="st-carousel" data-t="carousel">
    <Carousel
      ref="carousel"
      class="carousel-root"
      :items-to-show="props.itemsToShow"
      :items-to-scroll="props.itemsToScroll"
      :autoplay="autoPlayValue"
      :wrap-around="props.loop"
      :transition="300"
      :breakpoints="breakpointsSettings"
      @slide-start="handleSlideChange"
      @slide-end="handleFinish"
      @init="handleInit"
    >
      <slot />
      <template #addons="{ slidesCount }">
        <ul
          v-if="slidesCount > props.itemsToShow && props.paginate"
          class="pagination-list"
          data-t="pagination"
        >
          <li
            v-for="(item, index) in slidesCount -
            Math.floor(props.itemsToShow - props.itemsToScroll)"
            :key="item"
            class="pagination-item"
            :class="{
              active: activePage === index,
              static: !autoPlayValue,
              filled: index < activePage,
            }"
            data-t="pagination-item"
            @click="handlePaginationClick(index)"
          >
            <div class="start-point" />
            <div class="fill" />
          </li>
        </ul>
      </template>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css'
import { Carousel } from 'vue3-carousel'
import { delay } from '@st/utils'

interface CarouselConfig {
  itemsToShow?: number
  itemsToScroll?: number
  autoplay?: number
}
interface CarouselData {
  data: {
    slidesCount: Ref<number>
  }
  slideTo: (index: number) => void
  next: () => void
  prev: () => void
  restartCarousel: () => void
}
interface SlideOnStartData {
  slidingToIndex: number
}
interface SlideOnEndData {
  currentSlideIndex: number
  slidesCount: number
}
interface Props {
  paginate?: boolean
  itemsToShow?: number
  itemsToScroll?: number
  autoplay?: number
  loop?: boolean
  gap?: number
  breakpoints?: Record<string, CarouselConfig> | null
}

const props = withDefaults(defineProps<Props>(), {
  paginate: true,
  itemsToShow: 1,
  itemsToScroll: 1,
  autoplay: 6000,
  loop: false,
  gap: 16,
  breakpoints: null,
})

const carousel = ref<CarouselData | null>(null)
const breakpointsSettings = computed(() => props.breakpoints as any)

const slidesGap = computed(() => `${props.gap}px`)

const activePage = ref(-1)
function handleSlideChange(data: SlideOnStartData) {
  if (!data) return

  activePage.value = data.slidingToIndex - 1
}
function resetPagination() {
  carousel.value?.slideTo(0)
  activePage.value = 0
}
function handlePaginationClick(slideIndex: number) {
  carousel.value?.slideTo(slideIndex)
  activePage.value = slideIndex
}

async function handleInit() {
  await nextTick()
  activePage.value = 0
}

async function handleFinish(data: SlideOnEndData) {
  if (!data) return

  const paginationsDotsCount =
    data.slidesCount - Math.floor(props.itemsToShow - props.itemsToScroll)
  if (paginationsDotsCount === data.currentSlideIndex) {
    await delay(props.autoplay)
    resetPagination()
    carousel.value?.restartCarousel()
  }
}

const autoPlayValue = ref(0)
onMounted(async () => {
  autoPlayValue.value = props.autoplay
  carousel.value?.restartCarousel()
})

const progessAnimationTime = computed(() => `${props.autoplay / 1000 + 1}s`)
</script>

<style>
/* stylelint-disable */
.carousel__track {
  margin: 0;
}

.carousel__slide {
  padding: 0 calc(v-bind(slidesGap) / 2);
}

.carousel-root {
  &.is-dragging {
    .carousel__slide {
      pointer-events: none;
    }
  }
}
/* stylelint-enable */
</style>

<style scoped>
.pagination-list {
  display: flex;
  gap: var(--spacing-100);
  justify-content: center;

  margin: 0;
  margin-top: var(--spacing-200);
  padding: 0;

  list-style: none;
}

.pagination-item {
  --dot-width-active: 40px;
  --dot-width: 8px;

  will-change: max-width;
  cursor: pointer;

  overflow: hidden;

  width: 100%;
  max-width: var(--dot-width); /* stylelint-disable-line */
  height: 8px;

  background: var(--button-ghost-default);
  border-radius: var(--border-radius-full);

  transition:
    max-width 0.2s ease-in-out,
    background-color 0.2s ease-in-out;

  &.paused .fill {
    animation-play-state: paused;
  }

  &.filled {
    background-color: var(--button-ghost-default);
  }

  &.active {
    position: relative;
    display: flex;
    max-width: var(--dot-width-active); /* stylelint-disable-line */

    .start-point {
      width: 8px;
      height: 8px;
      border-radius: var(--border-radius-full);
    }

    .fill {
      will-change: transform;

      transform-origin: center left;

      width: var(--dot-width-active); /* stylelint-disable-line */
      height: 100%;
      margin-left: calc(var(--spacing-100) * -1);

      background-color: var(--text-link);
      border-radius: var(--border-radius-full);

      animation: fill-dot v-bind(progessAnimationTime) ease forwards;
      animation-play-state: running;
    }

    &.static {
      max-width: var(--dot-width); /* stylelint-disable-line */

      .fill {
        display: none;
      }

      &.active {
        .start-point {
          background-color: var(--text-link);
        }
      }
    }
  }
}

@keyframes fill-dot {
  0% {
    transform: scaleX(0.1);
  }

  100% {
    transform: scaleX(1);
  }
}
</style>
